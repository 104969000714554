body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*, *::before, *::after {
  box-sizing: inherit;
}

html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
  overflow-x: hidden;
  /* overflow-y: hidden; */
}

html body {
  padding: 0;
  margin: 0;
  /* overflow-x: inherit; */
}

/* .App {
  height: 100vh;
  width: 100vw;
  max-width: 900px;
  padding: 1rem;
  background-color: lightpink;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Cool infinite background scrolling animation.
 * Twitter: @kootoopas
 */

/* Exo thin font from Google. */
@import url(https://fonts.googleapis.com/css?family=Exo:100);

.bg-url {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAABnSURBVHja7M5RDYAwDEXRDgmvEocnlrQS2SwUFST9uEfBGWs9c97nbGtDcquqiKhOImLs/UpuzVzWEi1atGjRokWLFi1atGjRokWLFi1atGjRokWLFi1af7Ukz8xWp8z8AAAA//8DAJ4LoEAAlL1nAAAAAElFTkSuQmCC");
  background-size: 50px 50px;
}

/* Animations */
@-webkit-keyframes bg-scrolling-reverse {
  100% { background-position: 50px 50px; }
}
@-moz-keyframes    bg-scrolling-reverse {
  100% { background-position: 50px 50px; }
}
@-o-keyframes      bg-scrolling-reverse {
  100% { background-position: 50px 50px; }
}
@keyframes         bg-scrolling-reverse {
  100% { background-position: 50px 50px; }
}

@-webkit-keyframes bg-scrolling {
  0% { background-position: 50px 50px; }
}
@-moz-keyframes    bg-scrolling {
  0% { background-position: 50px 50px; }
}
@-o-keyframes      bg-scrolling {
  0% { background-position: 50px 50px; }
}
@keyframes         bg-scrolling {
  0% { background-position: 50px 50px; }
}

/* Main styles */
/* body { */
  /* opacity: 0.3; */
  /* margin-top: 13.5rem; */
  /* margin: 0;
  color: #999;
  font-family: "Poppins", sans-serif; */
  /* font: 400 16px/1.5 exo, ubuntu, "segoe ui", helvetica, arial, sans-serif; */
  /* text-align: center; 
   background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAABnSURBVHja7M5RDYAwDEXRDgmvEocnlrQS2SwUFST9uEfBGWs9c97nbGtDcquqiKhOImLs/UpuzVzWEi1atGjRokWLFi1atGjRokWLFi1atGjRokWLFi1af7Ukz8xWp8z8AAAA//8DAJ4LoEAAlL1nAAAAAElFTkSuQmCC") repeat 0 0;
   -webkit-animation: bg-scrolling-reverse .92s infinite; 
  -moz-animation:    bg-scrolling-reverse .92s infinite; 
  -o-animation:      bg-scrolling-reverse .92s infinite;
  animation:         bg-scrolling-reverse .92s infinite; 
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function:    linear;
  -o-animation-timing-function:      linear;
  animation-timing-function:         linear;  */
/* } */

/* body::before {
  content: "INFINITY";
  font-size: 8rem;
  font-weight: 100;
  font-style: normal;
} */
